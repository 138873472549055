import React from 'react'
import Github from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import Vector1 from '../../img/Vector1.png'
import Vector2 from "../../img/Vector2.png";
import girl from '../../img/girl.png'
import thumbup from '../../img/thumbup.png'
import Crown from "../../img/crown.png";
import glassesimoji from "../../img/glassesimoji.png";
import './Intro.css'
import Floating from '../FloatingDiv/Floating';
import { themeContext } from '../../Context';
import { useContext } from 'react';
import { motion } from 'framer-motion'


const Intro = () => {

  const transition = { duration: 2, type: "spring" }; 
  

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode
  return (
    <div className="intro">
      <div className="i-left">
        <div className="i-name">
          <span style={{ color: darkMode ? "white" : "" }}>Hy! I Am </span>
          <span>Vân Anh</span>
          <span style={{ color: darkMode ? "white" : "" }}>
            Đố ai quét sạch lá rừng ? Đố ai khuyên được anh ngừng yêu em?
          </span>
        </div>

        <button className="button i-button">Love you</button>
        <div className="i-icons">
          <img src={Github} />
          <img src={LinkedIn} />
          <img src={Instagram} />
        </div>
      </div>
      <div className="i-right">
        <img src={Vector1} />
        <img src={Vector2} />
        <img src={girl} />
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          src={glassesimoji}
          alt=""
        />
        <motion.div
          initial={{ left: "59%" }}
          whileInView={{ left: "50%" }}
          transition={transition}
          style={{ top: "-10%", left: "59%" }}
        >
          <Floating image={Crown} txt1="Xinh Đẹp" />
        </motion.div>
        <motion.div
          initial={{ left: "-30%" }}
          whileInView={{ left: "-5%" }}
          transition={transition}
          style={{ top: "14rem", left: "-3rem" }}
        >
          <Floating image={thumbup} txt1="Dễ Thương" />
        </motion.div>

        {/* blur divs */}
        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "15rem",
            width: "40rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
}

export default Intro